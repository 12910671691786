.audio-modal {
  position: fixed;
  bottom: -300px;
  left: 0;
  width: 100%;
  z-index: 999999;
  transition: all 0.4s ease-in-out;
}

.audio-modal-open {
  bottom: 0;
}

.big__playbar {
  .audioplayer:not(.audioplayer-playing) .audioplayer-playpause button {
    content: "";
    justify-content: center;
    width: 0;
    height: 0;
    margin-left: 2px;
    border-top: 7px solid transparent;
    border-right: none;
    border-bottom: 7px solid transparent;
    border-left: 12px solid var(--white);
  }
  border: 1px solid var(--border);
  border-radius: 12px;
  padding: 12px 24px;
  background: var(--cmnbox);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 850px) {
    display: block;
  }

  .middle__audioboxes {
    margin-left: -100px;

    @media screen and (max-width: 850px) {
      margin-left: 0;
    }
  }
  .audioplayer-volume-adjust div div {
    position: absolute;
    top: 0;
    left: 0;
    height: 2px;
    background-color: var(--base);
  }
  .circle__add {
    border: 1px solid var(--pra);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    margin-right: 14px;
  }
  .cross__btnsnone {
    padding: 0;
    outline: none;
    border: unset;
    margin-left: 10px;
  }
  .leftside__text {
    width: 150px;
  }
  .audioplayer-bar {
    position: absolute;
    top: -6px;
    left: 0;
    width: 99%;
    .audioplayer-bar-loaded {
      background: #6d6f72;
    }
    .audioplayer-bar-played {
      background: var(--base);
    }
  }
  .modal-switch {
    position: absolute;
    right: 49%;
    gap: 32px;

    @media screen and (max-width: 850px) {
      right: 60%;
    }

    svg {
      color: var(--pra);
      @media screen and (max-width: 600px) {
        display: none;
      }
    }
  }

  .audioplayer-playpause {
    order: 9;
    border: unset !important;
    background: var(--base) !important;
    width: 38px;
    height: 38px;
    color: var(--white);
    @media screen and (max-width: 850px) {
      margin-left: 250px;
    }
    @media screen and (max-width: 800px) {
      margin-left: 220px;
    }
    @media screen and (max-width: 750px) {
      margin-left: 180px;
    }

    @media screen and (max-width: 700px) {
      margin-left: 150px;
    }

    @media screen and (max-width: 650px) {
      margin-left: 130px;
    }
    @media screen and (max-width: 600px) {
      margin-left: auto;
      margin-right: 0px;
    }
  }
  .righttrun-one {
    padding-left: 24px;

    @media screen and (max-width: 850px) {
      position: absolute;
      right: 250px;
    }
    @media screen and (max-width: 750px) {
      position: absolute;
      right: 260px;
    }

    @media screen and (max-width: 650px) {
      position: absolute;
      right: 200px;
    }
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
  .righttrun-two {
    padding-left: 24px;

    @media screen and (max-width: 850px) {
      position: absolute;
      right: 200px;
    }

    @media screen and (max-width: 650px) {
      position: absolute;
      right: 160px;
    }

    @media screen and (max-width: 600px) {
      display: none;
    }
  }
  .audioplayer-volume {
    position: absolute;
    right: 135px;

    @media screen and (max-width: 850px) {
      right: 24px;
    }

    @media screen and (max-width: 600px) {
      display: none;
    }
  }
  .audioplayer-time {
    color: var(--white);
    border: 1px solid #212529;
    border-radius: 100px;
    padding: 4px 20px;
    border-left: unset;
    width: 60px;
    order: 1;
  }
  .audioplayer-time.audioplayer-time-duration {
    margin-right: 116px;

    @media screen and (max-width: 850px) {
      margin-right: 0px;
    }
  }
  .audioplayer-time-current {
    margin-right: 7px;
  }
}
