//footer//
.footer__section {
  background: var(--cmnbg);
}
.footer__top {
  padding: 80px 0 40px;

  @include breakpoint(max-sm) {
    padding: 40px 0 40px;
  }
}
.footer__widget {
  .link__attach {
    gap: 64px;
    @include breakpoint(max-x4l) {
      gap: 20px;
    }
    @include breakpoint(max-xxxl) {
      flex-wrap: wrap;
    }
  }
  .footer__form {
    margin-top: 60px;
    .input-item {
      position: relative;
      width: 100%;
      padding: 15px;
      &::after {
        content: "";
        position: absolute;
        display: block;
        height: 100%;
        width: 100% !important;
        left: 0;
        top: 0;
        background-color: #232627;
        transform: skewX(15deg);
      }
    }
    input {
      width: 100%;
      position: relative;
      background: transparent;
      z-index: 2;
      border: unset;
      outline: none;
    }

    ::placeholder {
      color: var(--white);
    }
    button {
      background: var(--base);
      padding: 16px 24px;
      font-size: 16px;
      font-weight: 500;
      @include bodyfont;
      color: var(--white);
      transform: skewX(15deg);
      span {
        transform: skewX(-15deg);
        display: block;
      }
    }
  }
}
.quick__space {
  padding-right: 60px;
  @include breakpoint(max-md) {
    padding-right: 15px;
  }
}
.footercontact__link {
  padding-left: 60px;
  @include breakpoint(max-xl) {
    padding-left: 10px;
  }
  @include breakpoint(max-lg) {
    padding-top: 30px;
  }
}
.footer__streamio {
  padding: 0 55px;
  border-left: 1px dashed var(--border);
  border-right: 1px dashed var(--border);
  .level__message {
    max-width: 200px;
    margin: 0 auto;
  }
  @include breakpoint(max-xl) {
    padding: 0 10px;
  }
  @include breakpoint(max-sm) {
    padding: 0 10px;
    border-left: none;
    border-right: none;
    .footer__widget {
      text-align: left !important;
      img {
        margin-bottom: 10px;
      }
      a {
        margin-bottom: 10px;
      }
    }
    .level__message {
      margin: 0 0;
      text-align: left;
    }
  }
}
.footer__bottom {
  padding: 30px 0 30px;
  justify-content: space-between;
  border-top: 1px solid var(--headborder);
  p {
    .design__side {
      border-left: 2px solid var(--headborder);
      padding-left: 12px;
      margin-left: 12px;
    }
  }
  @include breakpoint(max-md) {
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    text-align: center;
    padding: 20px 0;
  }
  @include breakpoint(max-xxs) {
    gap: 6px;
    p {
      font-size: 10px;
    }
    ul {
      li {
        a {
          font-size: 10px;
        }
      }
    }
  }
}
//footer//

//subcribtion section
.cmn__form {
  justify-content: space-between;
  background: var(--white);
  border-radius: 100px;
  padding: 12px 12px;
  input {
    width: 100%;
    background: transparent;
    outline: none;
    border: unset;
    color: var(--pragraph2);
    font-size: 16px;
    font-weight: 400;
    padding-left: 12px;
    @include bodyfont;
    @include breakpoint(max-sm) {
      padding-left: 8px;
      font-size: 12px;
    }
  }
  .cmn--btn {
    padding: 0;
    width: 180px;
    padding: 5px 5px 8px;
    height: 45px;
    border: unset;
    @include breakpoint(max-sm) {
      font-size: 14px;
    }
  }
}
//subcribtion two section
