/*--Button Area Start--*/
.cmn--btn {
  padding: 13px 32px 14px;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  display: inline-block;
  text-transform: capitalize;
  position: relative;
  background: var(--base);
  overflow: hidden;
  gap: 12px;
  color: var(--white);
  @include bodyfont;
  transform: skewX(15deg);

  span {
    position: relative;
    z-index: 1;
    transition: all 0.5s;
    display: block;
    transform: skewX(-15deg);
    letter-spacing: 1px;
  }
  &::before {
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    width: 0px;
    height: 0px;
    background: var(--hover);
    transition: all 0.5s;
    background: var(--base2);
  }
  &:hover {
    color: var(--white);
    &::before {
      width: 100%;
      height: 100%;
    }
  }
  @include breakpoint(max-md) {
    padding: 10px 20px 10px;
  }
  @include breakpoint(max-xs) {
    padding: 8px 20px 8px;
    font-size: 14px;
  }
}

.cmn--btn__outline {
  @extend .cmn--btn;
  background: transparent;
  border: 1px solid var(--base);
  transition: color 0.1s;
  &::before {
    background: var(--hover);
    background: var(--white);
    transition: all 0.5s;
  }

  &:hover {
    color: var(--body);
  }
}

.cmn--btn__home {
  @extend .cmn--btn;
  background: var(--white);
  color: black;
  border: 1px solid var(--base);
  transition: color 0.1s;
  &::before {
    background: var(--hover);
    background: var(--white);
    transition: all 0.5s;
  }

  &:hover {
    color: var(--body);
  }
}

.cmn--btntwo {
  background: #232627;
  &::before {
    bottom: 0;
    left: 0;
  }
}
.outline__btn {
  background: transparent;
  border: 1px solid var(--base);
  padding: 11px 25px 13px;
  span {
    color: var(--base);
  }
  &:hover {
    span {
      color: var(--title);
    }
  }
}

.cmbtn {
  padding: 8px 16px;
  background: var(--viewbox);
  font-weight: 500;
  @include bodyfont;
  color: var(--white);
  align-items: center;
}
.cmn__simple {
  padding: 12px 40px;
  background: var(--base);
  font-size: 18px;
  font-weight: 500;
  @include bodyfont;
  color: var(--white);
}
.cmn__simple2 {
  padding: 12px 32px 15px;
  background: var(--cmnbox);
  font-size: 18px;
  font-weight: 500;
  @include bodyfont;
  color: var(--white);
  transition: all 0.4s;
  &:hover {
    background: var(--base);
  }
}
/*--Button Area End--*/
