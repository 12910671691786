//trending Section
.trending__item {
  .thumb {
    .pause__abs {
      position: absolute;
      top: 44%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: all 0.4s;
      visibility: hidden;
      .audioplayer {
        .audioplayer-playpause {
          width: 60px;
          height: 60px;
          @include breakpoint(max-xl) {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
    .artist__popup {
      position: absolute;
      left: 0;
      bottom: -50px;
      width: 100%;
      padding: 0 20px 0;
      opacity: 0;
      transition: all 0.4s;
    }
    .podcast__viwer {
      .live {
        background: var(--base2);
        padding: 4px 12px;
        display: inline-block;
      }
      .viewer {
        background: var(--viewbox);
        padding: 4px 12px;
        display: inline-block;
      }
      padding: 0px 40px;
      @include breakpoint(max-x4l) {
        padding: 0 15px;
      }
      transition: all 0.4s;
      top: -80px;
      left: 0;
      width: 100%;
      position: absolute;
    }
    img {
      width: 100%;
    }
  }
  .trending__bbar {
    background: var(--base);
    padding: 16px 10px;
    position: absolute;
    bottom: -40px;
    left: 0;
    width: 100%;
    opacity: 0;
    transition: all 0.4s;
  }

  &:hover {
    .thumb {
      img {
        transform: scale(1.1);
      }
      .trending__bbar {
        opacity: 1;
        bottom: 0;
      }
      .pause__abs {
        opacity: 1;
        visibility: visible;
      }
      .artist__popup {
        opacity: 1;
        bottom: 10px;
        h5 {
          a {
            color: var(--white);
          }
        }
      }
      .podcast__viwer {
        top: 10px;
      }
    }
    .content {
      h5 {
        a {
          color: var(--base);
        }
        span {
          color: var(--base);
        }
      }
    }
  }
  @include breakpoint(max-xxl) {
    .trending__bbar {
      padding: 10px 5px;
    }
  }
}
//trending Section

.play-button-container {
  .button-play {
    position: absolute;
    left: 50%;
    top: 50%;
    opacity: 0;
    padding: 5px;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    color: var(--white);
    background: var(--base);
    transform: translate(-50%, -50%);
    transition: all 0.4s ease-in-out;
  }

  &:hover {
    .button-play {
      opacity: 1;
    }
  }
}

//scrollbar Section
.swiper-scrollbar.swiper-scrollbar-horizontal {
  height: 2px;
  background: var(--base-opacity);
  position: initial;
  border-radius: 0;
}
.swiper-scrollbar-drag {
  background: var(--base);
  height: 5px;
  // width: 177px !important;
  border-radius: 0;
  top: -1px;
  @include breakpoint(max-xs) {
    width: 100px !important;
  }
}
//scrollbar Section

//moods Section
.moods__item {
  background: var(--cmnbg);
  padding: 24px 24px;
  transition: all 0.4s;
  .thumb {
    position: relative;
    .pause__abs {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      visibility: hidden;
      opacity: 0;
      transition: all 0.4s;
    }
  }
  @include breakpoint(max-xxl) {
    padding: 16px 16px;
  }
  .content {
    @include breakpoint(max-xxxl) {
      @include breakpoint(xl) {
        h5 {
          font-size: 16px;
        }
      }
    }
  }
  &:hover {
    background: var(--base);
    .thumb {
      img {
        transform: scale(0.9);
      }
      .pause__abs {
        visibility: visible;
        opacity: 1;
      }
    }
    .content {
      span {
        color: var(--white);
      }
    }
  }
  @include breakpoint(max-sm) {
    max-width: 300px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .thumb {
      width: 100px;
      .video__style {
        width: 34px;
        height: 34px;
        svg {
          font-size: 18px;
        }
      }
    }
  }
}
//moods Section

//workout Section
.workout__section {
  .moods__item {
    .content {
      @include breakpoint(max-xxxl) {
        @include breakpoint(xl) {
          span {
            font-size: 10px;
          }
        }
      }
    }
  }
}
//workout Section

//feature show Section
.f__showcontent {
  background: var(--cmnbox);
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.08);
  padding: 24px 24px;
  margin-right: 24px;
  margin-left: 24px;
  transform: translateY(-120px);
  margin-bottom: -120px;
  transition: all 0.4s;
  .show__date {
    display: block;
    padding-left: 44px;
    &::before {
      position: absolute;
      top: 10px;
      left: 0;
      width: 35px;
      height: 1px;
      background: var(--base2);
      content: "";
    }
    @include breakpoint(max-xs) {
      padding-left: 18px;
      &::before {
        width: 15px;
      }
    }
  }
  .show__listen {
    gap: 24px;
  }
  @include breakpoint(max-xxl) {
    padding: 20px 18px;
    margin-right: 0;
    margin-left: 0;
    transform: translateY(-60px);
    margin-bottom: -60px;
    .show__listen {
      gap: 14px;
    }
  }
  @include breakpoint(max-xs) {
    padding: 20px 16px;
    transform: translateY(-30px);
    margin-bottom: -30px;
    p {
      font-size: 14px;
    }
    .show__listen {
      gap: 8px;
      li {
        a {
          svg {
            font-size: 20px;
          }
          font-size: 10px;
        }
      }
    }
  }
}
.featured__show {
  &:hover {
    .thumb {
      img {
        transform: scale(1.1);
      }
    }
    .f__showcontent {
      background: var(--base);
      .show__date {
        &::before {
          background: var(--white);
        }
      }
      span {
        color: var(--white);
      }
      .show__listen {
        svg {
          color: var(--white) !important;
        }
      }
      p {
        color: var(--white);
      }
      .cmn__arrow40 {
        background: var(--white);
        svg {
          color: var(--base);
        }
      }
    }
  }
}

.featured__show-right {
  @include breakpoint(max-xl) {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }

  @include breakpoint(max-lg) {
    grid-template-columns: 1fr;
    gap: 0px;
  }
  .showbox {
    width: 100% !important;

    @include breakpoint(max-xs) {
      flex-wrap: wrap;
      justify-content: center;

      img {
      }
    }
  }
}

.show__rightbox {
  @include breakpoint(max-lg) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 15px;
    .showbox {
      width: calc(97% / 2);
    }
  }
  @include breakpoint(max-sm) {
    gap: 15px;
    display: grid;
    flex-wrap: wrap;
    .showbox {
      width: 100% !important;
    }
  }
}
.showbox {
  background: var(--cmnbox);
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.08);
  padding: 16px 20px;
  gap: 20px;
  .s__content {
    .cmn__arrow40 {
      min-width: 32px;
      min-height: 32px;
      svg {
        font-size: 19px;
      }
    }
    .show__listen {
      gap: 24px;
    }
  }
  @include breakpoint(max-x4l) {
    padding: 14px 14px;
    gap: 8px;
    .s__content {
      h5 {
        font-size: 16px;
      }
      .show__listen {
        gap: 14px;
        li {
          a {
            font-size: 10px;
          }
        }
      }
      @include breakpoint(xxl) {
        .cmn__arrow40 {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  @include breakpoint(max-xxl) {
    // flex-wrap: wrap;
  }
}
//feature show Section

//product Section
.product__item {
  .product__badge {
    background: var(--base);
    padding: 16px 10px;
    svg {
      flex-shrink: 0;
      border-right: 1px solid var(--white);
      padding-right: 12px;
      width: 60px;
      margin-right: 12px;
    }
    transition: all 0.4s;
    position: absolute;
    bottom: -70px;
    left: 0;
    width: 100%;
  }
  .content {
    @include breakpoint(max-x4l) {
      h5 {
        font-size: 16px;
      }
      span {
        font-size: 12px;
      }
    }
  }
  &:hover {
    .product__badge {
      bottom: 0;
    }
    .content {
      .white {
        color: var(--base);
      }
    }
  }
  @include breakpoint(max-x4l) {
    .product__badge {
      padding: 8px 10px;
      font-size: 12px;
    }
  }
}
//product Section

//latest Section
.latest__item {
  .thumb {
    .pause__abs {
      position: absolute;
      top: 50%;
      left: 50%;
      visibility: hidden;
      opacity: 0;
      transition: all 0.4s;
      transform: translate(-50%, -50%);
      .audioplayer {
        .audioplayer-playpause {
          width: 60px;
          height: 60px;
          @include breakpoint(max-xl) {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }
  .l__badge {
    position: absolute;
    top: 24px;
    right: 24px;
  }
  .video__style {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    visibility: hidden;
    opacity: 0;
  }

  .content {
    &:hover {
      h4 {
        a {
          color: var(--base);
          text-decoration: underline;
        }
      }
    }
  }
  &:hover {
    .thumb {
      .pause__abs {
        visibility: visible;
        opacity: 1;
      }
      img {
        transform: scale(1.1);
      }
    }
  }
  @include breakpoint(max-x4l) {
    .content {
      h4 {
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
}
.l__badge {
  padding: 4px 12px 6px;
  display: inline-block;
  background: var(--base2);
  color: var(--white);
}
//latest Section

//latest upgrade Section
.latest__songsidebar {
  position: sticky;
  top: 100px;
}
.latest__songwrap {
  table {
    border: none;
    border-collapse: separate;
    border-spacing: 0em 1em;
    thead {
      tr {
        th {
          background: transparent;
          padding-bottom: 15px;
          border-bottom: 1px solid #212529;
        }
      }
    }
    tr {
      .d__custom {
        @include breakpoint(max-lg) {
          display: none !important;
        }
      }
      td {
        padding: 12px 24px;
        border: none;
        background: var(--cmnbg);
        transition: all 0.4s;
        @include breakpoint(max-lg) {
          width: 100%;
          padding: 10px 14px;
        }
      }
    }
  }
  .dropdown-toggle {
    background: none;

    &::after {
      display: none;
    }
  }
  .dropdown-menu {
    background: var(--cmnbox);
    .dropdown-item {
      &:hover {
        background: transparent;
        color: var(--pra);
      }
    }
  }
}
.modal__playlist {
  background: var(--cmnbox);
  padding: 20px 20px 20px;
  .btn {
    background: var(--viewbox);
    padding: 8px 8px;
    display: inline-block;
    width: 47%;
  }
  .modal-header {
    border-bottom: unset;
    margin-bottom: 25px;
    padding: 0 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btn-clos {
      background: transparent;
      svg {
        font-size: 26px;
      }
    }
  }
  .new {
    padding: 4px 8px 6px;
    background: var(--viewbox);
    font-size: 12px;
    color: var(--base2);
    display: block;
    font-weight: 500;
  }
  .modal-body {
    padding: 0 0;
    .report__track {
      width: 100%;
      background: var(--border);
      color: var(--white);
      padding: 10px 10px;
    }
    ::placeholder {
      color: var(--white);
      font-size: 14px;
    }
  }
  .modal-footer {
    padding: 0 0;
    border-top: unset;
  }
}
.bgnone__music {
  td {
    background: none !important;
    padding: 10px 0px !important;
    .dropdown {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: var(--viewbox);
      display: flex;
      align-items: center;
      justify-content: center;
      @include breakpoint(max-xl) {
        width: 24px;
        height: 24px;
      }
    }
  }
}
.overlayadd {
  background: rgba(0, 0, 0, 0.626);
  z-index: 9999;
}
.modal {
  .modal-bottom {
    background: transparent;
    .modal-content {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}

.modal-backdrop.show {
  opacity: 0;
  position: relative;
}

//big play bar
// .big__playbar {
//   .audioplayer:not(.audioplayer-playing) .audioplayer-playpause a {
//     content: "";
//     justify-content: center;
//     width: 0;
//     height: 0;
//     margin-left: 2px;
//     border-top: 7px solid transparent;
//     border-right: none;
//     border-bottom: 7px solid transparent;
//     border-left: 12px solid var(--white);
//   }
//   border: 1px solid var(--border);
//   border-radius: 12px;
//   padding: 12px 24px;
//   background: var(--cmnbox);
//   position: relative;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   width: 100%;
//   .middle__audioboxes {
//     margin-left: 200px;
//   }
//   .audioplayer-volume-adjust div div {
//     position: absolute;
//     top: 0;
//     left: 0;
//     height: 2px;
//     background-color: var(--base);
//   }
//   .circle__add {
//     border: 1px solid var(--pra);
//     border-radius: 50%;
//     width: 20px;
//     height: 20px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     font-size: 18px;
//     margin-right: 14px;
//   }
//   .cross__btnsnone {
//     padding: 0;
//     outline: none;
//     border: unset;
//     margin-left: 10px;
//   }
//   .leftside__text {
//     width: 150px;
//   }
//   .audioplayer-bar {
//     position: absolute;
//     top: -6px;
//     left: 0;
//     width: 99%;
//     .audioplayer-bar-loaded {
//       background: #6d6f72;
//     }
//     .audioplayer-bar-played {
//       background: var(--base);
//     }
//   }
//   .switch {
//     position: absolute;
//     right: 39%;
//     gap: 32px;
//     svg {
//       color: var(--pra);
//     }
//     @include breakpoint(max-xxl) {
//       right: 34%;
//     }
//     @include breakpoint(max-xxl) {
//       right: 42%;
//     }
//   }
//   .audioplayer-playpause {
//     order: 9;
//     border: unset !important;
//     background: var(--base) !important;
//     width: 38px;
//     height: 38px;
//   }
//   .righttrun {
//     padding-left: 24px;
//   }
//   .audioplayer-volume {
//     order: 1;
//     position: absolute;
//     right: 135px;
//   }
//   .audioplayer-time {
//     color: var(--white);
//     border: 1px solid #212529;
//     border-radius: 100px;
//     padding: 4px 20px;
//     border-left: unset;
//     width: 60px;
//     order: 1;
//   }
//   .audioplayer-time.audioplayer-time-duration {
//     margin-right: 116px;
//   }
//   .audioplayer-time-current {
//     margin-right: 7px;
//   }
//   @include breakpoint(max-lg) {
//     padding: 12px 20px;
//     text-align: center;
//     flex-wrap: wrap;
//     display: grid;
//     justify-content: flex-start;
//     width: 100%;
//     .leftside__text {
//       margin-bottom: 10px;
//     }
//     .small__center {
//       justify-content: flex-start;
//     }
//     .audioplayer-bar {
//       position: absolute;
//       width: 95%;
//     }
//     .audioplayer {
//       justify-content: flex-start;
//       gap: 5px;
//     }
//     .righttrun {
//       text-align: left;
//       margin: 10px 0;
//       padding-left: 0;
//       .pra {
//         margin-right: 29px;
//       }
//     }
//     .audioplayer-time-current {
//       margin-left: 0;
//     }
//     .audioplayer-playpause {
//       order: 1;
//       position: absolute;
//       top: 84px;
//       left: 20px;
//     }
//     .middle__audioboxes {
//       flex-wrap: wrap;
//     }
//     .audioplayer-volume {
//       position: initial;
//     }
//     .switch {
//       position: initial;
//       justify-content: flex-start;
//       width: 100%;
//       margin-top: 60px;
//       margin-bottom: 10px;
//     }
//     .audioplayer-time.audioplayer-time-duration {
//       margin-right: 0px;
//     }
//   }
// }

.backdrop {
  @include breakpoint(xxxl) {
    z-index: 9999;
    .width__maintain {
      min-width: 1600px !important;
      .modal-content {
        background: none;
      }
    }
  }
  @include breakpoint(max-xxxl) {
    z-index: 9999;
    .width__maintain {
      min-width: 1400px !important;
      .modal-content {
        background: none;
      }
    }
  }
  @include breakpoint(max-xxl) {
    z-index: 9999;
    .width__maintain {
      min-width: 1140px !important;
      .modal-content {
        background: none;
      }
    }
  }
  @include breakpoint(max-xl) {
    z-index: 9999;
    .width__maintain {
      min-width: 980px !important;
      .modal-content {
        background: none;
      }
    }
  }
  @include breakpoint(max-lg) {
    z-index: 9999;
    .width__maintain {
      min-width: 280px !important;
      .modal-content {
        background: none;
      }
    }
  }
}
//big play bar

//play share
.play__shapewrap {
  background: var(--cmnbox);
  padding: 24px 24px;
  max-width: 550px;
  margin: 0 auto;
  .modal-body {
    padding: 0 0;
    .nav {
      border-bottom: 1px solid var(--headborder);
      margin-bottom: 10px;
      gap: 40px;
      .nav-item {
        outline: none;
        .nav-link {
          background: none;
          font-size: 18px;
          font-weight: 500;
          @include bodyfont;
          color: var(--pra);
          padding: 0 0;
          outline: none;
          border: none;
          padding-bottom: 12px;
          &.active {
            color: var(--base2);
            border-bottom: 1px solid var(--base2);
            padding-bottom: 12px;
          }
        }
      }
    }
    .custom__social {
      li {
        a {
          width: 52px;
          height: 52px;
          background: #334c8c;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          svg {
            color: var(--white);
            font-size: 24px;
          }
          @include breakpoint(max-lg) {
            width: 38px;
            height: 38px;
            svg {
              font-size: 20px;
            }
          }
        }
        &:nth-child(2) {
          a {
            background: #00a6de;
          }
        }
        &:nth-child(3) {
          a {
            background: #c3266b;
          }
        }
        &:nth-child(4) {
          a {
            background: #5662f6;
          }
        }
        &:nth-child(5) {
          a {
            background: #2aa81a;
          }
        }
        &:nth-child(6) {
          a {
            background: #e80000;
          }
        }
        &:nth-child(7) {
          a {
            background: #4065d6;
          }
        }
        &:nth-child(8) {
          a {
            background: #0057ff;
          }
        }
      }
      @include breakpoint(max-xs) {
        flex-wrap: wrap;
      }
    }
    .thmb_url {
      margin-top: 20px;
      width: 100%;
      padding: 10px 12px 14px;
      text-align: center;
      background: #070707;
      border: unset;
    }
    .embed__input {
      gap: 4px;
      .thmb_url {
        margin-top: 0;
        padding: 10px 5px 14px !important;
      }
    }
  }
  .modal-footer {
    border-top: unset;
    padding: 0 0;
    margin-top: 24px;
    .btn {
      background: var(--viewbox);
      padding: 8px 10px 10px;
      border-radius: 2px;
      color: var(--white);
      width: 234px;
    }
  }
  @include breakpoint(max-xs) {
    padding: 24px 14px;
  }
}
.online__embeded {
  .online__thumb {
    width: 130px;
    height: 130px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .pause__block {
    margin-top: 15px;
    .audioplayer {
      .audioplayer-playpause {
        width: 32px;
        height: 32px;
      }
    }
  }
  .song__bar {
    width: 200px;
    img {
      width: 100%;
    }
  }
  @include breakpoint(max-xxs) {
    flex-wrap: wrap;
  }
}
//play share

//custom table
.ctablemy {
  tbody {
    .citem__border {
      td {
        border-bottom: 1px solid var(--headborder);
      }
    }
  }
}
.weekly__tracks {
  background: var(--cmnbg);
  padding: 30px;
  @include breakpoint(max-xxl) {
    padding: 20px;
  }
  position: sticky;
  top: 80px;

  .button-play {
    opacity: 1;
    padding: 5px;
    border: 1px solid var(--base);
    width: 40px;
    height: 40px;
    border-radius: 100%;
    color: var(--base);
    background: transparent;
    transition: all 0.4s ease-in-out;

    &:hover {
      color: var(--white);
      // background: var(--base);
    }
  }
}

.custom-button-play {
  .button-play {
    opacity: 1;
    padding: 5px;
    border: 1px solid var(--base);
    width: 40px;
    height: 40px;
    border-radius: 100%;
    color: var(--base);
    background: transparent;
    transition: all 0.4s ease-in-out;

    &:hover {
      color: var(--white);
      background: var(--base);
    }
  }
}

.weeklytop__tracks {
  li {
    border-radius: 12px;
    border: 1px solid var(--headborder);
    padding: 12px 20px;
    @include breakpoint(max-xxl) {
      padding: 10px 10px;
    }
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    .pause__block {
      .audioplayer-playpause {
        background: transparent;
        border: 1px solid var(--base);
      }
      .audioplayer:not(.audioplayer-playing) .audioplayer-playpause a {
        border-left: 12px solid var(--base);
      }
      &:hover {
        .audioplayer:not(.audioplayer-playing) .audioplayer-playpause a {
          border-left: 12px solid var(--white);
        }
      }
    }
  }
}
//custom table

//latest upgrade Section

//genres Section
.genres__song {
  background: var(--cmnbox);
  .content {
    a {
      .playlist {
        color: var(--base);
      }
    }
  }
  &:hover {
    .content {
      a {
        color: var(--white);
        .playlist {
          color: var(--white);
        }
      }
    }
  }
}
.pause__block {
  .audioplayer:not(.audioplayer-playing) .audioplayer-playpause:hover {
    background: var(--base);
  }
  .audioplayer {
    .audioplayer-playpause {
      width: 40px;
      height: 40px;
      background: var(--base);
      border: unset;
    }
    .audioplayer-time.audioplayer-time-current {
      display: none;
    }
    .audioplayer-bar {
      display: none;
    }
    .audioplayer-time.audioplayer-time-duration {
      display: none;
    }
    .audioplayer-volume {
      display: none;
    }
  }
  .audioplayer:not(.audioplayer-playing) .audioplayer-playpause a {
    content: "";
    justify-content: center;
    width: 0;
    height: 0;
    margin-left: 2px;
    border-top: 7px solid transparent;
    border-right: none;
    border-bottom: 7px solid transparent;
    border-left: 12px solid var(--white);
  }
}
//genres Section

//common details Section
.bebgadd__box {
  position: relative;
  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 380px;
    background: var(--cmnbox);
    z-index: -1;
  }
}
.conveter__head {
  h2 {
    @include breakpoint(max-lg) {
      font-size: 29px;
      margin-right: 14px;
    }
    @include breakpoint(max-md) {
      font-size: 22px;
      margin-right: 14px;
    }
    @include breakpoint(max-xxs) {
      font-size: 18px;
      margin-right: 14px;
    }
  }
  .conveter__follow {
    gap: 24px;
  }
  .dropdown-toggle {
    background: var(--viewbox);
    width: 40px;
    height: 40px;
    border-radius: 50%;

    &::after {
      display: none;
    }
  }
  .dropdown-menu {
    background: var(--cmnbox);
    .dropdown-item {
      &:hover {
        background: transparent;
        color: var(--pra);
      }
    }
  }
  justify-content: space-between;
}
.conveter__barwrap {
  width: 100%;
  gap: 162px;
  .audio__probar {
    width: 100%;
    img {
      width: 100%;
    }
  }

  img {
    @include breakpoint(max-lg) {
      display: block !important;
      margin: 0 auto !important;
    }
  }
  .audioplayer-playpause {
    @include breakpoint(max-sm) {
      margin: 5px auto;
    }
  }
  .online__audiowrap {
    position: relative;
    .audioplayer-bar {
      display: block !important;
      position: absolute;
      bottom: 90px;
      left: 50%;
      height: 12px;
      transform: translateX(-50%);
      width: 100%;
      margin: 0 0;
      &::before {
        height: 0;
      }
      @include breakpoint(max-lg) {
        bottom: 80px;
      }
      @include breakpoint(max-sm) {
        bottom: 170px;
      }
    }
    .audioplayer-bar-played {
      height: 12px;
      border-radius: 100px;
      background: var(--base);
      top: 11px;
      left: 8px;
      width: 25%;
      &::after {
        display: none;
      }
    }
    .audioplayer-bar-loaded {
      z-index: 1;
      height: 24px;
      background: var(--viewbox);
      border-radius: 100px;
    }
    .like__dislike {
      @include breakpoint(max-sm) {
        flex-wrap: wrap;
        display: grid !important;
        justify-content: space-around !important;
      }
    }
  }
  @include breakpoint(max-xxxl) {
    gap: 24px;
  }
  @include breakpoint(max-lg) {
    flex-wrap: wrap;
  }
}
//genres description
.genres__description {
  padding: 36px 36px;
  background: var(--cmnbg);
  @include breakpoint(max-xxl) {
    padding: 20px 20px;
  }
  @include breakpoint(max-xxs) {
    padding: 20px 14px;
  }
  .nav {
    border-bottom: 1px solid var(--headborder);
    margin-bottom: 10px;
    gap: 40px;
    .nav-item {
      outline: none;
      .nav-link {
        background: none;
        font-size: 18px;
        font-weight: 500;
        @include bodyfont;
        color: var(--pra);
        padding: 0 0;
        outline: none;
        border: none;
        padding-bottom: 12px;
        &.active {
          color: var(--base2);
          border-bottom: 1px solid var(--base2);
          padding-bottom: 12px;
        }
      }
    }
  }
}
.listend__thumb {
  a {
    &:not(:first-child) {
      margin-left: -8px;
    }
  }
}
.reply__content {
  display: none;
  .small__author {
    gap: 16px;
    @include breakpoint(max-xxs) {
      gap: 8px;
    }
  }
  form {
    background: var(--cmnbg);
    border-radius: 100px;
    padding: 4px;
    justify-content: space-between;
    input {
      width: 400px;
      color: var(--whitesub);
      font-size: 14px;
      padding: 8px 10px;
      background: transparent;
      border: unset;
      outline: none;
      @include breakpoint(max-xxl) {
        width: 300px;
      }
      @include breakpoint(max-xxs) {
        width: 200px;
      }
    }
    ::placeholder {
      color: var(--whitesub);
    }
    button {
      background: transparent;
      svg {
        color: var(--base);
        font-size: 22px;
        padding-right: 10px;
      }
    }
  }
}
.comments__box {
  .author__attach {
    gap: 12px;
    .cont__box {
      .headtext {
        margin-bottom: -1px;
      }
    }
    @include breakpoint(max-xxs) {
      flex-wrap: wrap;
    }
  }
}
.main__comment {
  textarea {
    width: 100%;
    background: var(--cmnbg);
    padding: 20px 20px;
    border: unset;
    outline: none;
    color: var(--white);
    font-size: 18px;
    font-weight: 500;
    @include bodyfont;
  }
  ::placeholder {
    color: var(--white);
  }
}
//genres description
//common details Section

//moods allsong Section
.moods__allsong {
  table {
    tbody {
      tr {
        .d__custom {
          display: none;
        }
        .upgrade__left {
          gap: 12px;
          @include breakpoint(max-xxxl) {
            gap: 8px;
            span {
              font-size: 12px;
            }
          }
        }
        td {
          span {
            @include breakpoint(max-xl) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
//moods allsong Section

//trending Section
.trending__selected {
  border-top: 1px solid var(--border);
  border-bottom: 1px solid var(--border);
  padding: 12px 0;
  .select__lefts {
    gap: 24px;
    .nice-select {
      padding: 0 0;
      font-size: 16px;
      font-weight: 500;
      color: var(--white);
      border: none;
      background: transparent;
      .current {
        padding-right: 28px;
      }
      &::after {
        border-bottom: 1.5px solid var(--white);
        border-right: 1.5px solid var(--white);
        width: 10px;
        height: 10px;
      }
      .list {
        background: var(--cmnbg);
        li {
          background: none;
          padding-right: 10px;
        }
      }
    }
  }
  form {
    background: var(--cmnbg);
    padding: 0 20px;
    border-radius: 100px;
    input {
      background: transparent;
      border: unset;
      color: var(--pra);
      font-size: 14px;
      font-weight: 400;
      padding: 12px 5px 14px;
    }
    button {
      background: none;
      color: var(--pra);
    }
    ::placeholder {
      color: var(--pra);
    }
  }
  .nav {
    background: var(--cmnbox);
    border-radius: 100px;
    border: unset;
    .nav-item {
      .nav-link {
        padding: 12px 40px;
        font-size: 14px;
        font-weight: 400;
        outline: none;
        border: none;
        @include bodyfont;
        color: var(--pra);
        border-radius: 100px;
        &.active {
          color: var(--white);
          background: var(--base);
          border: unset;
          border-radius: 100px;
          outline: none;
        }
        &:hover {
          border: unset;
          outline: none;
          isolation: none;
        }
      }
    }
    @include breakpoint(max-lg) {
      display: flex;
      align-items: center;
      justify-content: center;
      // width: 100%;
    }
    @include breakpoint(max-sm) {
      padding: 14px 5px;
      margin: 0 auto;
      width: fit-content;
      display: flex;
      flex-direction: column;
      border-radius: 5px;
    }
  }
  @include breakpoint(max-lg) {
    flex-wrap: wrap;
    gap: 14px;
    .select__lefts {
      flex-wrap: wrap;
      gap: 10px;
      justify-content: center;
      width: 100%;
    }
  }
}
//trending Section

//artist Section
.artist__allhead {
  gap: 18px;
  .artist__allcontent {
    margin-right: 40px;
    .artist__list {
      max-width: 290px;
      display: flex;
      flex-wrap: wrap;
      li {
        width: calc(100% / 2);
        padding: 6px 2px 6px 14px;
        position: relative;
        &::before {
          position: absolute;
          content: "";
          left: 0;
          top: 16px;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background: var(--white);
        }
      }
    }
  }
  @include breakpoint(max-xl) {
    flex-direction: column;
  }

  @include breakpoint(max-sm) {
    align-items: center;
    img {
      width: 100%;
    }
    .artist__allcontent {
      margin-right: 0;
    }
  }
}
//artist Section

//explore Section
.story__change {
  position: relative;
  width: 252px;
  height: 252px;
  .file-upload {
    display: none;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .p-image {
    position: absolute;
    bottom: -20px;
    left: 0;
    text-align: center;
    margin: 0 auto;
    width: 100%;
    color: var(--white);
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    .bbgadd {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--title);
      margin: 0 auto;
    }
    .boxes {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--base2);
      color: var(--white);
      border-radius: 50%;
      margin: 0 auto;
      position: relative;
      z-index: 1;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
//story change two
.story__change2 {
  display: flex;
  gap: 16px;
  align-items: center;
  background: var(--cmnbox);
  padding: 24px 24px;
  width: 341px;
  position: absolute;
  bottom: 0;
  left: 0;
  .content {
    a {
      svg {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border: 1px solid var(--base2);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &:hover {
        color: var(--base2);
      }
    }
  }
  .thumb {
    position: relative;
    width: 110px;
    height: 110px;
    img {
      width: 100%;
      height: 100%;
    }
    .boxes {
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
      background: var(--base);
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        cursor: pointer;
      }
      input {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
      &:hover {
        input {
          cursor: pointer !important;
        }
        svg {
          cursor: pointer !important;
        }
      }
    }
  }
  @include breakpoint(max-xs) {
    width: 260px;
    padding: 18px 14px;
    gap: 10px;
    .thumb {
      width: 80px;
      height: 80px;
    }
  }
}
//explore Section

//hotsong Section
.hotsong__section {
  .moods__item {
    background: var(--cmnbox);
    &:hover {
      background: var(--base);
    }
    .thumb {
      margin-bottom: 20px;
    }
  }
}
//hotsong Section

//topsong Section
.topsinger__song {
  .moods__item {
    background: var(--cmnbox);
    &:hover {
      background: var(--base);
    }
  }
}
//topsong Section

//Event Section
.event__wrapper {
  background: url(../../../../public/img/event/event-bg.jpg) no-repeat center
    center;
  background-size: cover;
  padding: 80px 20px 220px;
  .event__boxes {
    max-width: 1080px;
    margin: 0 auto;
    position: relative;
    .create__btn {
      border: 1px solid var(--white);
      display: inline-block;
      padding: 9px 24px;
      width: 146px;
    }
    .cmn-button-prev2 {
      position: absolute;
      top: 138px;
      left: -2px;
    }
    .cmn-button-next2 {
      position: absolute;
      top: 138px;
      right: -2px;
    }
  }
  .event__sldebtn {
    @include breakpoint(max-xxxl) {
      justify-content: center;
      margin-top: 30px;
      .cmn-button-prev2 {
        position: initial;
      }
      .cmn-button-next2 {
        position: initial;
        right: -2px;
      }
    }
  }
  @include breakpoint(max-xs) {
    padding: 70px 10px 200px;
  }
}
.event__sliderwrap {
  max-width: 1020px;
  margin: 0 auto;
  .event__slider {
    text-align: center;
    margin: 0 auto;
    .sbox {
      padding-right: 16px;
      position: relative;
      width: 55px;
      margin: 0 auto;
      &::before {
        position: absolute;
        top: 0;
        left: 100%;
        content: "";
        width: 1px;
        height: 100%;
        background: var(--white);
      }
    }
  }
}
//Event Section

//browse event Section
.browse__events {
  background: var(--cmnbox);
  padding: 32px 32px;
  @include breakpoint(max-xs) {
    padding: 24px 10px;
  }
}
.browse__position {
  .browse__boxes {
    max-width: 1080px;
    margin: 0 auto;
  }
  transform: translateY(-120px);
  margin-bottom: -120px;
}
.browse__box {
  border: 1px solid var(--headborder);
  padding: 12px 12px;
  @include breakpoint(max-xs) {
    padding: 10px 10px;
  }
  .event__check {
    min-width: 32px;
    min-height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #bab9bb;
    svg {
      color: var(--base);
      font-size: 20px;
    }
    @include breakpoint(max-xs) {
      min-width: 26px;
      min-height: 26px;
      svg {
        font-size: 14px;
      }
    }
  }
}
.browse__right {
  .create__hand {
    background: var(--base);
    justify-content: center;
    color: var(--white);
    padding: 9px 8px;
  }
}
//browse event Section
.inputPassword {
  position: relative;
}

.toggle-icon {
  position: absolute;
  z-index: 20;
  top: 59px;
  right: 20px;
  cursor: pointer;
  @include breakpoint(max-xxl) {
    top: 53px;
  }
  @include breakpoint(max-lg) {
    top: 46px;
  }
}
//Event create Section
.event__createcover {
  .event__coverhead {
    padding: 60px 24px;
    position: relative;
    background: #232627;
    .file-upload {
      display: none;
    }
    .arrow {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      inset: 0;
    }
    .thingkin {
      position: relative;
      &:hover {
        cursor: pointer;
      }
      svg {
        margin: 0 auto;
      }
    }
  }

  .cover__form {
    background: var(--cmnbox);
    padding: 40px 40px;

    .cover__grp {
      textarea,
      .nice-select,
      input {
        padding: 16px 20px;
        border: 1px solid #232627;
        color: #bab9bb;
        @include bodyfont;
        font-weight: 400;
        outline: none;
        background: var(--cmnbox);
        width: 100%;
        border-radius: 0;
        &::after {
          right: 23px;
          border-bottom: 1px solid var(--pra);
          border-right: 1px solid var(--pra);
          width: 10px;
          height: 10px;
        }
        .list {
          li {
            background: var(--cmnbox);
          }
        }
        @include breakpoint(max-xl) {
          font-size: 14px;
        }
      }
      ::placeholder {
        color: #bab9bb;
      }

      label {
        color: #fefefe !important;
        @include breakpoint(max-xxl) {
          font-size: 14px;
        }
      }
    }
    .file__uploadedcover {
      .fileupload {
        border: 1px dashed #232627;
        padding: 32px 10px;
        position: relative;
        .up__fileup {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          &:hover {
            cursor: pointer;
          }
        }

        svg {
          margin: 0 auto;
        }
      }
    }
    @include breakpoint(max-sm) {
      padding: 24px 16px;
    }
  }
}
//Event create Section

//checkout pages
.checkout__wrapper {
  padding: 60px 60px;
  background: var(--cmnbox);
  .cover__form {
    padding: 0 0;
  }
  h3 {
    @include breakpoint(max-xl) {
      font-size: 24px;
    }
    @include breakpoint(max-sm) {
      font-size: 20px;
    }
  }
  @include breakpoint(max-md) {
    padding: 30px 24px;
  }
  @include breakpoint(max-sm) {
    padding: 24px 18px;
  }
}
.checkout__account {
  gap: 60px;
  @include breakpoint(max-md) {
    gap: 20px;
  }
  @include breakpoint(max-xs) {
    flex-wrap: wrap;
    gap: 5px;
  }
}
.message__title {
  @include breakpoint(max-md) {
    font-size: 18px;
  }
}
//checkout pages

//order side
.order__side {
  .order__boxes {
    border: 1px solid #232627;
    padding: 24px 24px;
    @include breakpoint(max-sm) {
      padding: 20px 16px;
    }
  }
}
.payment__check {
  padding: 24px 24px;
  border: 1px solid #232627;
  @include breakpoint(max-sm) {
    padding: 20px 16px;
  }
}
.pay__check {
  .form-check-input {
    width: 24px;
    height: 24px;
    vertical-align: top;
    appearance: none;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-color: #141718;
    outline: none;
    border-color: var(--pra);
    box-shadow: none;
  }
}
.orborder {
  border-bottom: 1px solid #232627;
}
//order side

//feature show details
.tag__shareshow {
  max-width: 991px;
}
.list__show {
  li {
    position: relative;
    padding-left: 12px;
    &::before {
      position: absolute;
      top: 10px;
      left: 0;
      content: "";
      width: 4px;
      height: 4px;
      background: var(--white);
      border-radius: 50%;
    }
    @include breakpoint(max-sm) {
      font-size: 14px;
    }
  }
}
//feature show details

//signin section
.remember__radio {
  .form-check-input {
    border: 2px solid var(--white);
    border-radius: 8px;
  }
}
.sign__inbtn {
  .cmn--btn {
    padding: 16px 32px 19px;
  }
}
//signin section

//faq section
.faq__components {
  .accordion-item {
    border: none;
    background: none;
    border-bottom: 1px solid #212529;
    padding: 14px 0 16px;
    .accordion-header {
      .accordion-button {
        box-shadow: none;
        font-size: 18px;
        line-height: 150%;
        font-weight: 500;
        background: none;
        color: var(--white);
        border-radius: 0;
        padding: 0 0;
        @include bodyfont;
        @include breakpoint(max-sm) {
          font-size: 14px;
          padding-left: 14px;
        }
        &::after {
          background: none;
          border: 2px solid var(--pra);
          width: 24px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          border-radius: 50%;
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          content: "^";
          color: var(--pra);
          font-size: 14px;
        }
      }
      .accordion-button:not(.collapsed)::after {
        background-image: none;
        background: none;
        border: 2px solid var(--base);
        width: 24px;
        height: 24px;
        border-radius: 50%;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "^";
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--base);
      }
      .accordion-button:not(.collapsed) {
        color: var(--base);
        box-shadow: none;
      }
    }
  }
  .accordion-body {
    border-top: unset;
    padding: 10px 0 0;
    p {
      font-size: 16px;
      color: var(--pra);
      line-height: 150%;
      font-weight: 400;
      @include bodyfont;
      opacity: 0.7;
      @include breakpoint(max-sm) {
        font-size: 14px;
      }
    }
  }
}
.faq__tabs {
  background: #141718;
  padding: 32px 32px;
  .nav {
    border: unset;
    .nav-item {
      border: unset;
      &:not(:last-child) {
        margin-bottom: 24px;
      }
      .nav-link {
        width: 100%;
        border: unset;
        border-radius: 16px;
        transition: all 0.4s;
        padding: 0 0;
        display: block;
        background: transparent;
        justify-content: space-between;
        svg,
        span {
          transition: all 0.4s;
          @include breakpoint(max-xxxl) {
            font-size: 16px;
          }
        }
        &.active {
          border: unset;
          span {
            color: var(--base);
          }
          svg {
            color: var(--base);
          }
        }
      }
      &:hover {
        .nav-link {
          border: unset;
          span {
            color: var(--base);
          }
          svg {
            color: var(--base);
          }
        }
      }
    }
  }
}
//faq section

//pricing plan section
.price__section {
  .switch-label {
    text-align: center;
    opacity: 0.6;
    font-size: 18px;
    cursor: pointer;
    padding: 0 12px;
    color: var(--white);
  }
  .switch-label .save-money {
    color: #3498db;
    font-style: italic;
    padding-left: 0.5rem;
  }
  .save-money--mobile {
    color: #3498db;
    font-style: italic;
    display: none;
  }
  .switch-label.active {
    opacity: 1;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 42px;
    height: 20px;
    vertical-align: -50%;
    margin: 0;
  }
  .switch input {
    display: none;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--base);
    border-radius: 34px;
    -webkit-transition: 0.1s;
    transition: 0.1s;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: var(--white);
    border-radius: 50%;
    -webkit-transition: 0.1s;
    transition: 0.1s;
  }
  #js-pricing-switch input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }
  #js-pricing-switch input:checked + .slider:before {
    -webkit-transform: translateX(21px);
    -ms-transform: translateX(21px);
    transform: translateX(21px);
  }
  @media (max-width: 980px) {
    .switch-label {
      display: inline-block;
      width: auto;
    }

    .save-money {
      display: none;
    }

    .save-money--mobile {
      display: block;
      font-weight: bold;
    }
  }
}
.pricing__item {
  background: var(--cmnbox);
  padding: 32px 32px;
  @include breakpoint(max-lg) {
    padding: 24px 14px;
  }
}
.price__count {
  .adjust {
    .dollar {
      margin-right: -9px;
    }
  }
  .month {
    margin-bottom: 5px;
  }
  border-bottom: 1px solid #212529;
  padding-bottom: 12px;
}
.pricing__list {
  li {
    svg {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}
.pricing__btn {
  padding: 12px 12px 13px;
}
.before__price {
  position: relative;
  &::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    width: 100px;
    height: 80px;
    background: var(--base2);
    border-radius: 0 0 0 500px;
  }
}
//pricing plan section

//error page
.error__content {
  .thumb {
    width: 100%;
    max-width: 370px;
    margin: 0 auto 70px;
    img {
      width: 100%;
    }
  }
  .content {
    h1 {
      @include breakpoint(max-sm) {
        font-size: 29px;
      }
    }
  }
}
//error page

//profile section
.profile__cover .profile__coverchange {
  padding: 0px 0px;
  height: 500px;
  .thingkin {
    position: absolute;
    right: 28px;
    top: 18px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 20px;
    }
  }
}

.profile__tabsbar {
  background: var(--cmnbox);
  padding: 32px 24px;
  .nav {
    border: unset;
    display: flex;
    align-items: center;
    gap: 12px;
    .nav-item {
      .nav-link {
        outline: none;
        border: unset;
        background: #232627;
        border-radius: 100px;
        padding: 12px 40px 14px;
        font-size: 16px;
        font-weight: 500;
        color: var(--pra);
        &.active {
          background: var(--base);
          color: var(--white);
        }
        @include breakpoint(max-xs) {
          padding: 8px 20px 10px;
          font-size: 14px;
        }
      }
    }
  }
  @include breakpoint(max-xl) {
    padding: 24px 24px;
  }
  @include breakpoint(max-md) {
    .nav {
      gap: 8px;
    }
    padding: 24px 14px;
  }
}
.follower__box {
  background: #0f0f0f;
  padding: 40px 60px;
  .thumb {
    width: 160px;
    height: 160px;
    margin: 0 auto 20px;
    img {
      border-radius: 100%;
      width: 100%;
      height: 100%;
    }
    @include breakpoint(max-xs) {
      width: 100px;
      height: 100px;
    }
  }
  @include breakpoint(max-xxl) {
    padding: 20px 20px;
  }
}
.no__tracksfound {
  background: #0f0f0f;
  padding: 147px 10px;
  .boxes {
    svg {
      background: #232627;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 32px;
      color: var(--base2);
      margin: 0 auto 20px;
    }
  }
  @include breakpoint(max-lg) {
    padding: 120px 10px;
  }
}
.profile__editcheck {
  border-bottom: 1px solid #212529;
  padding-bottom: 20px;
  .pay__check {
    .form-check-input {
      border-radius: 7px;
      border: 2px solid var(--base);
      min-width: 24px;
    }
    .form-check-input:checked[type="checkbox"] {
      background-image: url(../../../../public/img/profile/check.png);
      background-size: 10px;
    }
  }
}
.profile__cancel {
  gap: 32px;
  align-items: center;
}
//profile section
