:root {
  --body: #070707;
  --white: #ffffff;
  --whitesub: #f5f6f7;
  --bgsection: #070707;
  --cmnbg: #0f0f0f;
  --cmnbox: #141718;
  --base: rgb(66, 90, 245); //#25A56A;
  --base-opacity: rgba(66, 90, 245, 0.5);
  --base2: #fd3658;
  --base3: #8e55ea;
  --pra: #bab9bb;
  --pra2: #e8ecef;
  --title: #121519;
  --ratting: #ffbe57;
  --headborder: #212529;
  --hover: #fff;
  --border: #212529;
  --pra3: #fefefe;
  --viewbox: #343839;
  --subtitle: #222e48;
  --pricieborder: #343e56;
  --base2border: rgba(255, 251, 31, 0.2);
  --teamborder: #404a60;
  --placeholder: #b2b6bf;
  --danger: #ed5050;
  --success: #3fca90;
  --success2: #2e7d32;
}
