@mixin breakpoint($point) {
    @if $point==xsmall {
        @media (min-width: 450px) {
            @content ;
        }
    }
    @else if $point==max-xxsmall {
        @media (max-width:450px) {
            @content ;
        }
    }
    @if $point==xxs {
        @media (min-width: 470px) {
            @content ;
        }
    }
    @else if $point==max-xxs {
        @media (max-width:470px) {
            @content ;
        }
    }
    @if $point==xs {
        @media (min-width: 500px) {
            @content ;
        }
    }
    @else if $point==max-xs {
        @media (max-width: 500px) {
            @content ;
        }
    }
    @if $point==sm {
        @media (min-width: 576px) {
            @content ;
        }
    }
    @else if $point==max-sm {
        @media (max-width: 575px) {
            @content ;
        }
    }
    @else if $point==md {
        @media (min-width: 768px) {
            @content ;
        }
    }
    @else if $point==max-md {
        @media (max-width: 767px) {
            @content ;
        }
    }
    @else if $point==lg {
        @media (min-width: 992px) {
            @content ;
        }
    }
    @else if $point==max-lg {
        @media (max-width: 991px) {
            @content ;
        }
    }
    @else if $point==xl {
        @media (min-width: 1200px) {
            @content ;
        }
    }
    @else if $point==max-xl {
        @media (max-width: 1199px) {
            @content ;
        }
    }
    @else if $point==xxl {
        @media (min-width: 1400px) {
            @content ;
        }
    }
    @else if $point==max-xxl {
        @media (max-width: 1399px) {
            @content ;
        }
    }
    @else if $point==xxxl {
        @media (min-width: 1600px) {
            @content ;
        }
    }
    @else if $point==max-xxxl {
        @media (max-width: 1600px) {
            @content ;
        }
    }
    @else if $point==x4l {
        @media (min-width: 1800px) {
            @content ;
        }
    }
    @else if $point==max-x4l {
        @media (max-width: 1800px) {
            @content ;
        }
    }
}

@mixin justify {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
@mixin wrap {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
}
@mixin flex {
	display: flex;
	align-items: center;
}


@mixin imgw {
	width: 100%;
	height: 100%;
}
@mixin gradient{
	background: linear-gradient(90deg, #1A4DBE 0%, #AE5EBE 100%);
}
@mixin bodyfont{
    font-family: 'Poppins', sans-serif;
}
@mixin headfont{
    font-family: 'borda', sans-serif;
}


